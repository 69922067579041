import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { Button } from "../../components";
import {
  Hero,
  SayHello,
  DiscoverFeatures,
  RequestDemo,
  AppInfo,
} from "./components";

import "./styles.css";

const Home = (props) => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (location.hash) {
        let elem = document.getElementById(location.hash.slice(1));
        if (elem) {
          elem.scrollIntoView({ behavior: "smooth" });
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      }
    }, 500);
  }, [location]);

  return (
    <>
      <Hero />
      <SayHello />
      <Button
        label="REQUEST DEMO"
        link="/request-demo"
        styles="homeHeroRequestDemoMobile"
        color="black"
      />
      <DiscoverFeatures />
      <AppInfo />
      <RequestDemo />
    </>
  );
};

export default Home;
